import * as React from 'react'
import { Link } from 'gatsby'
import './footer.scss'

const bestOfs = [
  {
    link: '/best-of/2024/',
    title: 'Best of 2024',
  },
  {
    link: '/best-of/2021/',
    title: 'Best of 2021',
  },
  {
    link: '/best-of/2020/',
    title: 'Best of 2020',
  },
  {
    link: '/best-of/jazz/2020/',
    title: 'Best jazz of 2020',
  },
]
const bestOfLinks = bestOfs.map(({ link, title }, index) => {
  return (
    <Link key={`bestof-${index}`} to={link}>
      { title }
    </Link>
  )
})

const Footer = ({ years = [], yearCount = {} }) => {
  const yearLinks = years.sort().map((year) => {
    const count = yearCount[year] || 0
    return (
      <Link key={`year-${year}`} to={`/year/${year}`}>
        {year} ({count})
      </Link>
    )
  })
  const renderYearLists = () => {
    if (yearLinks.length === 0) {
      return null
    }
    return (
      <div className="wrapper">
        <h3>Year lists</h3>
        <div className="year-links">{yearLinks}</div>
      </div>
    )
  }

  const renderBestOfs = () => {
    return (
      <div className="wrapper">
        <h3>Best of's</h3>
        <div className="bestof-links">{bestOfLinks}</div>
      </div>
    )
  }

  return (
    <>
      { renderBestOfs() }
      { renderYearLists() }
      <footer className="footer">
        2025, <a href="https://hearsay.se">hearsay.se</a>
      </footer>
    </>
  )
}

export default Footer
